import React, { useEffect, useState } from 'react';
import { Layout, Dropdown, Avatar, Button, Modal, Form, Input } from 'antd';
import type { MenuProps } from 'antd';
import { 
  UserOutlined, 
  AppstoreOutlined, 
  UnorderedListOutlined, 
  UploadOutlined, 
  CloudUploadOutlined,
  SearchOutlined,
  DollarOutlined,
  BugOutlined,
  FolderOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CommandPalette from '../../features/commandPalette/CommandPalette';
import { checkUserAccess, UserAccessLevel } from '../../auth/authService';
import { API_BASE_URL, getAuthHeaders } from '../../utils/api';
import './Header.css';

const { Header } = Layout;

interface AppHeaderProps {
  quoteRequestId?: string;
  newProp?: string;
}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const [userAccess, setUserAccess] = useState<UserAccessLevel>('none');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBugModalVisible, setIsBugModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchUserAccess = async () => {
      const access = await checkUserAccess();
      setUserAccess(access);
    };
    fetchUserAccess();
  }, []);

  const getMenuItems = (): Required<MenuProps>['items'] => {
    const baseItems: Required<MenuProps>['items'] = [
      {
        key: 'residual-check',
        label: <Link to="/residual-check">Residual Account List</Link>,
        icon: <UnorderedListOutlined />,
      },
      {
        key: 'master-residual',
        label: <Link to="/master-residual-list">Search by Rogers Account</Link>,
        icon: <SearchOutlined />,
      },
      {
        type: 'divider',
      },
      {
        key: 'renewals',
        label: <Link to="/renewal-check">Renewals</Link>,
        icon: <UnorderedListOutlined />,
      }
    ];

    // Only show specific items for admin users
    if (userAccess === 'admin') {
      baseItems.push(
        {
          type: 'divider',
        },
        {
          key: 'won-services',
          label: <Link to="/won-services">Won Services</Link>,
          icon: <UnorderedListOutlined />,
        },
        {
          key: 'incoming-wireline-payments',
          label: <Link to="/incoming-wireline-payments">Callidus Wireline Payments</Link>,
          icon: <UnorderedListOutlined />,
        },
        {
          key: 'product-compensation',
          label: <Link to="/product-compensation">Product Profit Dashboard</Link>,
          icon: <DollarOutlined />,
        },
        {
          type: 'divider',
        },
        {
          key: 'file-upload',
          label: 'File Upload',
          icon: <FolderOutlined />,
          children: [
            {
              key: 'residual-upload',
              label: <Link to="/residual-upload">Residual Statement Upload</Link>,
              icon: <UploadOutlined />,
            },
            {
              key: 'wireline-upload',
              label: <Link to="/wireline-upload">Wireline Statement Upload</Link>,
              icon: <CloudUploadOutlined />,
            },
            {
              key: 'callidus-upload',
              label: <Link to="/raw-excel-upload">Callidus Statement Upload</Link>,
              icon: <CloudUploadOutlined />,
            }
          ]
        }
      );
    }

    return baseItems;
  };

  return (
    <Header className="app-header">
      {/* Menu and Logo section */}
      <div className="header-left">
        <Dropdown 
          menu={{ items: getMenuItems() }}
          trigger={['click']}
          placement="bottomLeft"
          onOpenChange={setIsMenuOpen}
        >
          <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`}>
            <AppstoreOutlined />
          </div>
        </Dropdown>
        <Link to="/residual-check" style={{ textDecoration: 'none' }}>
          <div className="logo-container">
            <img src="/foxylogo.png" alt="Foxy Logo" className="logo-image" />
            <span className="logo-text">Ledger</span>
          </div>
        </Link>
      </div>

      {/* Command palette section - centered */}
      <div className="command-palette-container">
        <CommandPalette />
      </div>

      {/* Avatar section */}
      <div className="header-right">
        <Button 
          type="text"
          icon={<BugOutlined />}
          onClick={() => setIsBugModalVisible(true)}
          className="bug-submit-button"
        >
          Submit Bug
        </Button>
        <div className="user-avatar">
          <Avatar 
            icon={<UserOutlined />} 
            className="avatar-icon"
          />
        </div>

        {/* Bug Submit Modal */}
        <Modal
          title="Submit Bug Report"
          open={isBugModalVisible}
          onCancel={() => setIsBugModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={async (values) => {
              try {
                const headers = await getAuthHeaders();
                
                const bugData = {
                  ...values,
                  url: window.location.href,
                  userAgent: navigator.userAgent,
                  timestamp: new Date().toISOString()
                };
                
                const response = await fetch(`${API_BASE_URL}/createJiraIssue`, {
                  method: 'POST',
                  headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(bugData)
                });

                if (!response.ok) {
                  throw new Error('Failed to submit bug report');
                }

                const result = await response.json();
                
                Modal.success({
                  title: 'Bug Report Submitted',
                  content: (
                    <div>
                      <p>Thank you for your bug report!</p>
                      <p>You can track this issue at: <a href={result.issueUrl} target="_blank" rel="noopener noreferrer">{result.issueKey}</a></p>
                    </div>
                  ),
                });
                
                form.resetFields();
                setIsBugModalVisible(false);
              } catch (error) {
                console.error('Error submitting bug:', error);
                Modal.error({
                  title: 'Error',
                  content: 'Failed to submit bug report. Please try again later.',
                });
              }
            }}
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please enter a title' }]}
            >
              <Input placeholder="Brief description of the issue" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please describe the issue' }]}
            >
              <Input.TextArea 
                rows={4}
                placeholder="Please provide details about what happened and what you expected to happen"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Header>
  );
};

export default AppHeader;
