import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { listRevenueServices } from '../../utils/api';
import { WonService } from '../../types/wonServices';

const RenewalsPage: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<WonService[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isDevModalVisible, setIsDevModalVisible] = useState(false);

    const columnDefs: ColDef<WonService>[] = [
        { field: 'foxy_serviceid', headerName: 'Service ID', width: 120 },
        { field: 'foxy_Product.name', headerName: 'Product', width: 200 },
        { field: 'foxy_Account.name', headerName: 'Account', width: 300 },
        { field: 'foxy_AccountLocation.foxy_Building.foxy_fulladdress', headerName: 'Address', width: 300 },
        { field: 'foxy_Opportunity.name', headerName: 'Opportunity', width: 300 },
        { field: 'foxy_Opportunity.foxy_sfdcoppid', headerName: 'SFDC ID', width: 120 },
        { field: 'foxy_contractend', headerName: 'Contract End', width: 150 },
        { field: 'foxy_renewaltype', headerName: 'Renewal Type', width: 180 },
        { field: 'foxy_renewaldisposition', headerName: 'Disposition', width: 120 },
        { field: 'foxy_revenuetype', headerName: 'Revenue Type', width: 120 },
        { field: 'crc9f_existingmrr', headerName: 'Existing MRR', width: 120, type: 'numericColumn' },
        { field: 'foxy_mrr', headerName: 'MRR', width: 120, type: 'numericColumn' },
        { field: 'foxy_mrruptick', headerName: 'MRR Uptick', width: 120, type: 'numericColumn' },
        { field: 'foxy_tcv', headerName: 'TCV', width: 120, type: 'numericColumn' },
        { field: 'foxy_quantity', headerName: 'Quantity', width: 100, type: 'numericColumn' },
        { field: 'foxy_term', headerName: 'Term', width: 100, type: 'numericColumn' },
        { field: 'foxy_linemargin', headerName: 'Line Margin', width: 120, type: 'numericColumn' },
        { field: 'foxy_Opportunity.actualvalue', headerName: 'Actual Value', width: 120, type: 'numericColumn' },
        { field: 'foxy_Opportunity.actualclosedate', headerName: 'Close Date', width: 150 }
    ];

    const defaultColDef: ColDef<WonService> = {
        sortable: true,
        filter: true,
        resizable: true,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await listRevenueServices();
                if (response.value) {
                    setData(response.value);
                }
            } catch (err) {
                setError(err instanceof Error ? err.message : 'An error occurred');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div style={{ padding: '20px', position: 'relative', height: 'calc(100vh - 140px)' }}>
            {/* Developer button in top right */}
            <div style={{ 
                position: 'fixed',
                top: '84px',
                right: '20px',
                zIndex: 100
            }}>
                <Button 
                    type="primary" 
                    onClick={() => setIsDevModalVisible(true)}
                >
                    Developer
                </Button>
            </div>

            {/* AG Grid Table */}
            <div 
                className="ag-theme-alpine" 
                style={{ 
                    height: '100%',
                    width: '100%'
                }}
            >
                <AgGridReact<WonService>
                    rowData={data}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={100}
                    enableCellTextSelection={true}
                    suppressRowClickSelection={true}
                />
            </div>

            {/* Developer Modal */}
            <Modal
                title="Developer Data View"
                open={isDevModalVisible}
                onCancel={() => setIsDevModalVisible(false)}
                width={1000}
                footer={null}
            >
                <div>
                    <strong>Total Records: {data.length}</strong>
                </div>
                <pre style={{ 
                    maxHeight: 'calc(100vh - 300px)', 
                    overflow: 'auto',
                    backgroundColor: '#f5f5f5',
                    padding: '15px',
                    borderRadius: '4px',
                    marginTop: '10px'
                }}>
                    {JSON.stringify(data, null, 2)}
                </pre>
            </Modal>
        </div>
    );
};

export default RenewalsPage;
