import React, { useState, useMemo, useEffect } from 'react';
import { useRenewalCheckData } from './hooks/useRenewalCheckData';
import { RenewalCheckFilters } from './components/RenewalCheckFilters';
import { RenewalCheckGrid } from './tables/RenewalCheckGrid';

const RenewalsCheck: React.FC = () => {
  useEffect(() => {
    document.title = 'Renewals for Infusion Won Services';
    // Optionally, restore the original title when component unmounts
    return () => {
      document.title = 'Foxy Ledger';
    };
  }, []);

  const {
    accounts,
    isLoading,
    error,
    filterOptions,
    updateFilterOptions,
    applyFilters,
    updateLocalAccounts
  } = useRenewalCheckData();
  const [displayedRowCount, setDisplayedRowCount] = useState(accounts.length);

  // Calculate unique opportunities count
  const uniqueOpportunitiesCount = useMemo(() => {
    const uniqueOppIds = new Set(
      accounts
        .map(account => account.foxy_Opportunity?.foxy_sfdcoppid)
        .filter(Boolean)
    );
    return uniqueOppIds.size;
  }, [accounts]);

  return (
    <div style={{ padding: '8px 24px 16px' }}>
      <h2 style={{ 
        color: '#D1D5DA', 
        marginBottom: '8px',
        fontWeight: 500
      }}>
        Renewals for Infusion Won Services
      </h2>
      <div style={{ 
        color: '#8B949E', 
        fontSize: '14px', 
        marginBottom: '8px' 
      }}>
        Displaying {displayedRowCount} {displayedRowCount === 1 ? 'service' : 'services'} and {uniqueOpportunitiesCount} {uniqueOpportunitiesCount === 1 ? 'opportunity' : 'opportunities'}
      </div>

      <RenewalCheckFilters
        _selectedServices={filterOptions.selectedServices}
        _matchAllServices={filterOptions.matchAllServices}
        selectedResiduals={filterOptions.selectedResiduals}
        _searchTerm={filterOptions.searchTerm}
        _showNeedsScrubbing={filterOptions.showNeedsScrubbing}
        _onServiceChange={(value: string[]) => updateFilterOptions({ selectedServices: value })}
        _onMatchAllServicesChange={(checked: boolean) => updateFilterOptions({ matchAllServices: checked })}
        onResidualChange={(value: string[]) => updateFilterOptions({ selectedResiduals: value })}
        _onSearchChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilterOptions({ searchTerm: e.target.value })}
        _onScrubToggleChange={(checked: boolean) => updateFilterOptions({ showNeedsScrubbing: checked })}
        onApplyFilters={applyFilters}
      />

      <div style={{ height: 'calc(100vh - 200px)', position: 'relative' }}>
        {isLoading && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            color: '#D1D5DA'
          }}>
            Loading...
          </div>
        )}
        {error ? (
          <div style={{ color: '#D1D5DA', padding: '24px 24px 8px' }}>{error}</div>
        ) : (
          <RenewalCheckGrid 
            accounts={accounts} 
            onDisplayedRowsChanged={setDisplayedRowCount}
            onAccountsUpdated={updateLocalAccounts}
          />
        )}
      </div>
    </div>
  );
};

export default RenewalsCheck;
