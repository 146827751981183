import React, { useState, useEffect, useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  createColumnHelper,
  flexRender
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Switch, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { listAccountsForResidualCheck } from '../../utils/api';
import { getWirelineResidualsLabel } from '../../utils/wirelineResidualsMapper';
import wirelineResidualsMap from '../../utils/wirelineResidualsMapper';

const { Option } = Select;

interface Account {
  accountid: string;
  name: string;
  foxy_cable: boolean;
  foxy_datacentre: boolean;
  foxy_duns: string;
  foxy_fibreinternet: boolean;
  foxy_gpon: boolean;
  foxy_microsoft365: boolean;
  foxy_res: boolean;
  foxyflow_residualstotal: string;
  foxyflow_residualsnotes: string;
  foxy_ritaresidualnotes: string;
  foxy_sip: boolean;
  foxy_unison: boolean;
  foxy_wirelinemrr: string;
  foxyflow_wirelineresiduals: string;
  crc9f_residuallastscrub: string;
  crc9f_totalwonoppstcv: number | null;
}

const serviceColors = {
  Cable: '#fa8c16',         // orange
  'Data Centre': '#fa541c', // volcano
  'Fibre Internet': '#52c41a', // green
  GPON: '#13c2c2',         // cyan
  'Microsoft 365': '#722ed1', // purple
  RES: '#eb2f96',          // magenta
  SIP: '#1890ff',          // blue
  Unison: '#2f54eb',       // geekblue
};

const formatCurrency = (value: string | number | null | undefined) => {
  if (value === null || value === undefined) return '$0.00';
  const num = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(num)) return '$0.00';
  return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

const formatDate = (dateString: string) => {
  if (!dateString) return '-';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const getStatusStyle = (status: string) => {
  const label = getWirelineResidualsLabel(status);
  let color = '#d9d9d9';
  let background = '#2b2b2b';

  if (label === 'Active') {
    color = '#52c41a';
    background = '#162312';
  } else if (label === 'Pending Start') {
    color = '#1890ff';
    background = '#111d2c';
  } else if (label === 'Not Eligible') {
    color = '#faad14';
    background = '#2b1d11';
  } else if (label.startsWith('Issue')) {
    color = '#ff4d4f';
    background = '#2a1215';
  }

  return { color, background };
};

const StatusCell = ({ value }: { value: string }) => {
  const label = getWirelineResidualsLabel(value);
  const { color, background } = getStatusStyle(value);

  return (
    <div style={{
      backgroundColor: background,
      border: `1px solid ${color}`,
      borderRadius: '4px',
      padding: '4px 8px',
      color: color,
      fontSize: '12px',
      fontWeight: 500,
      display: 'inline-block',
      lineHeight: '1',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle'
    }}>
      {label}
    </div>
  );
};

const ServicesCell = ({ row }: { row: any }) => {
  const services = [];
  if (row.original.foxy_cable) services.push({ name: 'Cable', color: serviceColors.Cable });
  if (row.original.foxy_datacentre) services.push({ name: 'Data Centre', color: serviceColors['Data Centre'] });
  if (row.original.foxy_fibreinternet) services.push({ name: 'Fibre Internet', color: serviceColors['Fibre Internet'] });
  if (row.original.foxy_gpon) services.push({ name: 'GPON', color: serviceColors.GPON });
  if (row.original.foxy_microsoft365) services.push({ name: 'Microsoft 365', color: serviceColors['Microsoft 365'] });
  if (row.original.foxy_res) services.push({ name: 'RES', color: serviceColors.RES });
  if (row.original.foxy_sip) services.push({ name: 'SIP', color: serviceColors.SIP });
  if (row.original.foxy_unison) services.push({ name: 'Unison', color: serviceColors.Unison });

  return (
    <div style={{ 
      display: 'flex', 
      gap: '4px', 
      flexWrap: 'wrap',
      height: '100%',
      alignItems: 'center'
    }}>
      {services.map(service => (
        <div
          key={service.name}
          style={{
            backgroundColor: service.color + '1A',
            border: `1px solid ${service.color}`,
            borderRadius: '4px',
            padding: '4px 8px',
            color: service.color,
            fontSize: '12px',
            fontWeight: 500,
            display: 'inline-block',
            lineHeight: '1',
            whiteSpace: 'nowrap',
            verticalAlign: 'middle'
          }}
        >
          {service.name}
        </div>
      ))}
    </div>
  );
};

const ResidualCheckTanstackPage: React.FC = () => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Filter states
  const [selectedServices, setSelectedServices] = useState<string[]>(() => {
    return JSON.parse(localStorage.getItem('selectedServices') || '[]');
  });
  const [matchAllServices, setMatchAllServices] = useState<boolean>(() => {
    return JSON.parse(localStorage.getItem('matchAllServices') || 'false');
  });
  const [selectedResiduals, setSelectedResiduals] = useState<string[]>(() => {
    return JSON.parse(localStorage.getItem('selectedResiduals') || '[]');
  });
  const [searchTerm, setSearchTerm] = useState<string>(() => {
    return localStorage.getItem('searchTerm') || '';
  });
  const [showNeedsScrubbing, setShowNeedsScrubbing] = useState<boolean>(() => {
    return JSON.parse(localStorage.getItem('showNeedsScrubbing') || 'false');
  });

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await listAccountsForResidualCheck();
        setAccounts(response.value);
      } catch (err) {
        setError('Error fetching accounts. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  const filteredAccounts = useMemo(() => {
    let filtered = [...accounts];

    if (searchTerm) {
      filtered = filtered.filter(account => 
        account.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedServices.length > 0) {
      filtered = filtered.filter(account => {
        const accountServices = [
          account.foxy_cable && 'Cable',
          account.foxy_datacentre && 'Data Centre',
          account.foxy_fibreinternet && 'Fibre Internet',
          account.foxy_gpon && 'GPON',
          account.foxy_microsoft365 && 'Microsoft 365',
          account.foxy_res && 'RES',
          account.foxy_sip && 'SIP',
          account.foxy_unison && 'Unison'
        ].filter(Boolean) as string[];

        if (matchAllServices) {
          return selectedServices.length === accountServices.length &&
                 selectedServices.every(service => accountServices.includes(service));
        } else {
          return selectedServices.some(service => accountServices.includes(service));
        }
      });
    }

    if (selectedResiduals.length > 0) {
      filtered = filtered.filter(account => {
        const accountResidualValue = account.foxyflow_wirelineresiduals?.toString();
        return selectedResiduals.includes(accountResidualValue);
      });
    }

    if (showNeedsScrubbing) {
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - 120);

      filtered = filtered.filter(account => {
        if (!account.crc9f_residuallastscrub) return true;
        const scrubDate = new Date(account.crc9f_residuallastscrub);
        return scrubDate < cutoffDate;
      });
    }

    return filtered;
  }, [selectedServices, selectedResiduals, searchTerm, accounts, showNeedsScrubbing, matchAllServices]);

  const columnHelper = createColumnHelper<Account>();

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      size: 200,
    }),
    columnHelper.accessor('foxy_duns', {
      header: 'DUNS',
      size: 100,
    }),
    columnHelper.display({
      id: 'services',
      header: 'Services',
      size: 200,
      cell: ({ row }) => <ServicesCell row={row} />,
    }),
    columnHelper.accessor('foxyflow_residualstotal', {
      header: 'Residuals Total',
      size: 100,
      cell: ({ getValue }) => formatCurrency(getValue()),
    }),
    columnHelper.accessor('foxy_wirelinemrr', {
      header: 'Wireline MRR',
      size: 100,
      cell: ({ getValue }) => formatCurrency(getValue()),
    }),
    columnHelper.accessor('crc9f_totalwonoppstcv', {
      header: 'Won TCV',
      size: 100,
      cell: ({ getValue }) => formatCurrency(getValue()),
    }),
    columnHelper.accessor('foxyflow_wirelineresiduals', {
      header: 'Residual Status',
      size: 150,
      cell: ({ getValue }) => <StatusCell value={getValue()} />,
    }),
    columnHelper.accessor('crc9f_residuallastscrub', {
      header: 'Last Scrubbed',
      size: 100,
      cell: ({ getValue }) => formatDate(getValue()),
    }),
  ];

  const table = useReactTable({
    data: filteredAccounts,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  const handleServiceChange = (value: string[]) => {
    setSelectedServices(value);
    localStorage.setItem('selectedServices', JSON.stringify(value));
  };

  const handleMatchAllServicesChange = (checked: boolean) => {
    setMatchAllServices(checked);
    localStorage.setItem('matchAllServices', JSON.stringify(checked));
  };

  const handleResidualChange = (value: string[]) => {
    setSelectedResiduals(value);
    localStorage.setItem('selectedResiduals', JSON.stringify(value));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    localStorage.setItem('searchTerm', e.target.value);
  };

  const handleScrubToggleChange = (checked: boolean) => {
    setShowNeedsScrubbing(checked);
    localStorage.setItem('showNeedsScrubbing', JSON.stringify(checked));
  };

  if (isLoading) return <div style={{ color: '#D1D5DA', padding: '24px 24px 8px' }}>Loading...</div>;
  if (error) return <div style={{ color: '#D1D5DA', padding: '24px 24px 8px' }}>{error}</div>;

  return (
    <div style={{ padding: '8px 24px 16px' }}>
      <h2 style={{ 
        color: '#D1D5DA', 
        marginBottom: '8px',
        fontWeight: 500
      }}>
        Accounts for Residual Check (Tanstack)
      </h2>
      <div style={{ 
        color: '#8B949E', 
        fontSize: '14px', 
        marginBottom: '8px' 
      }}>
        Displaying {filteredAccounts.length} {filteredAccounts.length === 1 ? 'account' : 'accounts'}
      </div>

      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={6}>
          <Input
            placeholder="Search by company name"
            prefix={<SearchOutlined style={{ color: '#8B949E' }} />}
            onChange={handleSearchChange}
            value={searchTerm}
            style={{ 
              width: '100%',
              backgroundColor: '#1c1c1c',
              borderColor: '#424242',
              color: '#D1D5DA'
            }}
          />
        </Col>
        <Col span={6}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Filter by Services"
              onChange={handleServiceChange}
              allowClear
              value={selectedServices}
              dropdownStyle={{ backgroundColor: '#1c1c1c' }}
              className="dark-select"
            >
              {Object.keys(serviceColors).map(service => (
                <Option key={service} value={service}>{service}</Option>
              ))}
            </Select>
            <Switch
              checked={matchAllServices}
              onChange={handleMatchAllServicesChange}
              checkedChildren="All"
              unCheckedChildren="Any"
              style={{ minWidth: '55px' }}
            />
          </div>
        </Col>
        <Col span={6}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Filter by Residual Status"
            onChange={handleResidualChange}
            allowClear
            value={selectedResiduals}
            dropdownStyle={{ backgroundColor: '#1c1c1c' }}
            className="dark-select"
          >
            {wirelineResidualsMap.map(option => (
              <Option key={option.value} value={option.value}>{option.label}</Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Switch
              checked={showNeedsScrubbing}
              onChange={handleScrubToggleChange}
            />
            <span style={{ color: '#D1D5DA' }}>Show Needs Scrubbing (120+ days)</span>
          </div>
        </Col>
      </Row>

      <style>
        {`
          .dark-select .ant-select-selector {
            background-color: #1c1c1c !important;
            border-color: #424242 !important;
            color: #D1D5DA !important;
          }
          .dark-select .ant-select-selection-placeholder {
            color: #8B949E !important;
          }
          .dark-select .ant-select-selection-item {
            background-color: #2d2d2d !important;
            border-color: #424242 !important;
            color: #D1D5DA !important;
          }
          .ant-select-dropdown {
            background-color: #1c1c1c !important;
          }
          .ant-select-item {
            color: #D1D5DA !important;
          }
          .ant-select-item-option-selected {
            background-color: #2d2d2d !important;
          }
          .ant-select-item-option-active {
            background-color: #2d2d2d !important;
          }

          .tanstack-table {
            width: 100%;
            border-spacing: 0;
            border: 1px solid #424242;
            border-radius: 8px;
            overflow: hidden;
          }

          .tanstack-table thead {
            background-color: rgba(40,49,62,255);
          }

          .tanstack-table th {
            border-bottom: 1px solid #424242;
            padding: 12px 16px;
            text-align: left;
            color: #D1D5DA;
            font-weight: 500;
            white-space: nowrap;
          }

          .tanstack-table tbody tr {
            cursor: pointer;
            background-color: rgba(22,24,25,255);
          }

          .tanstack-table tbody tr:nth-of-type(odd) {
            background-color: rgba(31,40,54,255);
          }

          .tanstack-table tbody tr:hover {
            background-color: rgb(24, 26, 27);
          }

          .tanstack-table td {
            padding: 12px 16px;
            color: #D1D5DA;
            border-bottom: 1px solid #424242;
          }

          .tanstack-table tr:last-child td {
            border-bottom: none;
          }

          .pagination {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 16px 0;
            color: #D1D5DA;
          }

          .pagination button {
            background-color: #1c1c1c;
            border: 1px solid #424242;
            color: #D1D5DA;
            padding: 4px 8px;
            border-radius: 4px;
            cursor: pointer;
          }

          .pagination button:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          .pagination button:hover:not(:disabled) {
            background-color: #2d2d2d;
          }

          /* Custom Scrollbar Styling */
          /* WebKit Browsers */
          .tanstack-table-container::-webkit-scrollbar {
            width: 12px;
          }

          .tanstack-table-container::-webkit-scrollbar-track {
            background: #2b2b2b;
            border-radius: 6px;
          }

          .tanstack-table-container::-webkit-scrollbar-thumb {
            background-color: #424242;
            border-radius: 6px;
            border: 3px solid #2b2b2b;
          }

          /* Firefox */
          .tanstack-table-container {
            scrollbar-width: thin;
            scrollbar-color: #424242 #2b2b2b;
          }
        `}
      </style>

      <div style={{ 
        height: 'calc(100vh - 200px)',
        width: '100%',
        overflow: 'auto'
      }} className="tanstack-table-container">
        <table className="tanstack-table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th 
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{ width: header.getSize() }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr 
                key={row.id}
                onClick={() => navigate(`/residual-details/${row.original.accountid}`)}
              >
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <span>
          Page{' '}
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default ResidualCheckTanstackPage;
