import React, { useRef, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../../utils/formatters';
import { getWirelineResidualsLabel } from '../../../utils/wirelineResidualsMapper';
import { Account } from '../hooks/useResidualCheckData';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { CustomContextMenu } from '../components/CustomContextMenu';

interface ResidualCheckGridProps {
  accounts: Account[];
  onGridReady?: (params: any) => void;
}

interface ContextMenuState {
  show: boolean;
  x: number;
  y: number;
  rowData: any;
  columnField?: string;
}

const serviceColors = {
  Cable: '#fa8c16',
  'Fibre Internet': '#52c41a',
  GPON: '#13c2c2',
  'Microsoft 365': '#722ed1',
  RES: '#eb2f96',
  SIP: '#1890ff',
  Unison: '#2f54eb',
  'Data Centre': '#fa541c',
};

const formatDate = (dateString: string) => {
  if (!dateString) return '-';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const getStatusStyle = (status: string) => {
  const label = getWirelineResidualsLabel(status);
  let color = '#d9d9d9';
  let background = '#2b2b2b';

  if (label === 'Active') {
    color = '#52c41a';
    background = '#162312';
  } else if (label === 'Pending Start') {
    color = '#1890ff';
    background = '#111d2c';
  } else if (label === 'Not Eligible') {
    color = '#faad14';
    background = '#2b1d11';
  } else if (label.startsWith('Issue')) {
    color = '#ff4d4f';
    background = '#2a1215';
  }

  return { color, background };
};

const StatusCell = (props: any) => {
  const label = getWirelineResidualsLabel(props.value);
  const { color, background } = getStatusStyle(props.value);

  return (
    <div style={{
      backgroundColor: background,
      border: `1px solid ${color}`,
      borderRadius: '4px',
      padding: '4px 8px',
      color: color,
      fontSize: '12px',
      fontWeight: 500,
      display: 'inline-block',
      lineHeight: '1',
      textAlign: 'center',
    }}>
      {label}
    </div>
  );
};

const ServicesCell = (props: any) => {
  const services = [
    props.data.foxy_cable && { name: 'Cable', color: serviceColors.Cable },
    props.data.foxy_datacentre && { name: 'Data Centre', color: serviceColors['Data Centre'] },
    props.data.foxy_fibreinternet && { name: 'Fibre Internet', color: serviceColors['Fibre Internet'] },
    props.data.foxy_gpon && { name: 'GPON', color: serviceColors.GPON },
    props.data.foxy_microsoft365 && { name: 'Microsoft 365', color: serviceColors['Microsoft 365'] },
    props.data.foxy_res && { name: 'RES', color: serviceColors.RES },
    props.data.foxy_sip && { name: 'SIP', color: serviceColors.SIP },
    props.data.foxy_unison && { name: 'Unison', color: serviceColors.Unison }
  ].filter(Boolean) as { name: string; color: string }[];

  return (
    <div style={{ 
      display: 'flex', 
      gap: '4px', 
      flexWrap: 'wrap',
      height: '100%',
      alignItems: 'center'
    }}>
      {services.map(service => (
        <div
          key={service.name}
          style={{
            backgroundColor: service.color + '1A', // Add 10% opacity
            border: `1px solid ${service.color}`,
            borderRadius: '4px',
            padding: '4px 8px',
            color: service.color,
            fontSize: '12px',
            fontWeight: 500,
            display: 'inline-block',
            lineHeight: '1',
            whiteSpace: 'nowrap',
            verticalAlign: 'middle'
          }}
        >
          {service.name}
        </div>
      ))}
    </div>
  );
};

export const ResidualCheckGrid: React.FC<ResidualCheckGridProps> = ({ 
  accounts,
  onGridReady 
}) => {
  const navigate = useNavigate();
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [contextMenu, setContextMenu] = useState<ContextMenuState>({
    show: false,
    x: 0,
    y: 0,
    rowData: null,
    columnField: undefined
  });

  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.closest('.ag-row')) {
        event.preventDefault();
        const gridApi = gridRef.current?.api;
        if (gridApi) {
          const rowElement = target.closest('.ag-row');
          const rowId = rowElement?.getAttribute('row-id') || '';
          const rowNode = gridApi.getRowNode(rowId);
          const cell = target.closest('.ag-cell');
          const columnField = cell?.getAttribute('col-id') || undefined;
          if (rowNode) {
            setContextMenu({
              show: true,
              x: event.clientX,
              y: event.clientY,
              rowData: rowNode.data,
              columnField
            });
          }
        }
      }
    };

    const handleClick = (event: MouseEvent) => {
      if (contextMenu.show && !event.defaultPrevented) {
        setContextMenu(prev => ({ ...prev, show: false }));
      }
    };

    const gridContainer = gridContainerRef.current;
    if (gridContainer) {
      gridContainer.addEventListener('contextmenu', handleContextMenu);
      document.addEventListener('click', handleClick);
      return () => {
        gridContainer.removeEventListener('contextmenu', handleContextMenu);
        document.removeEventListener('click', handleClick);
      };
    }
  }, [contextMenu.show]);

  const columnDefs: ColDef[] = [
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'foxy_duns', headerName: 'DUNS', flex: 1 },
    { field: 'foxy_sfdcapiaccountowner', headerName: 'SFDC Owner', flex: 1 },
    { 
      headerName: 'Services',
      flex: 2,
      cellRenderer: ServicesCell,
      sortable: false,
      filter: false,
      cellStyle: { 
        display: 'flex',
        alignItems: 'center'
      }
    },
    { 
      field: 'foxyflow_residualstotal', 
      headerName: 'Residuals Total', 
      flex: 1,
      valueFormatter: params => formatCurrency(params.value)
    },
    { 
      field: 'foxy_wirelinemrr', 
      headerName: 'Wireline MRR', 
      flex: 1,
      valueFormatter: params => formatCurrency(params.value)
    },
    { 
      field: 'crc9f_totalwonoppstcv', 
      headerName: 'Won TCV', 
      flex: 1,
      valueFormatter: params => formatCurrency(params.value)
    },
    { 
      field: 'foxyflow_wirelineresiduals', 
      headerName: 'Residual Status', 
      flex: 1.5,
      cellRenderer: StatusCell,
      cellStyle: { 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      sortable: true,
      filter: true,
      filterValueGetter: params => getWirelineResidualsLabel(params.data.foxyflow_wirelineresiduals)
    },
    { 
      field: 'crc9f_residuallastscrub', 
      headerName: 'Last Scrubbed', 
      flex: 1,
      valueFormatter: params => formatDate(params.value)
    }
  ];

  const onRowDoubleClicked = (event: any) => {
    navigate(`/residual-details/${event.data.accountid}`);
  };

  return (
    <div 
      ref={gridContainerRef}
      className="ag-theme-quartz-auto-dark" 
      style={{ 
        height: '100%',
        width: '100%',
        borderRadius: '8px',
        overflow: 'hidden',
        '--ag-foreground-color': '#D1D5DA',
        '--ag-header-foreground-color': '#D1D5DA',
        '--ag-secondary-foreground-color': '#D1D5DA',
        '--ag-row-hover-color': 'rgb(24, 26, 27)',
        '--ag-selected-row-background-color': 'rgb(30, 32, 33)',
        '--ag-odd-row-background-color': 'rgb(22, 24, 25)',
      } as React.CSSProperties}
    >
      <AgGridReact
        rowData={accounts}
        columnDefs={columnDefs}
        onRowDoubleClicked={onRowDoubleClicked}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
        pagination={true}
        paginationPageSize={50}
        enableRangeSelection={true}
        onGridReady={onGridReady}
        ref={gridRef}
      />
      {contextMenu.show && (
        <CustomContextMenu
          position={{ x: contextMenu.x, y: contextMenu.y }}
          onClose={() => setContextMenu(prev => ({ ...prev, show: false }))}
          rowData={contextMenu.rowData}
          _columnField={contextMenu.columnField}
        />
      )}
    </div>
  );
};
