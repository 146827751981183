import React from 'react';
import { Input, Select, Switch, Row, Col, Button } from 'antd';
import { SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import { getWirelineResidualsLabel } from '../../../utils/wirelineResidualsMapper';

const { Option } = Select;

interface BaseScrubFiltersProps {
  selectedServices: string[];
  matchAllServices: boolean;
  selectedResiduals: string[];
  searchTerm: string;
  showNeedsScrubbing: boolean;
  onServiceChange: (value: string[]) => void;
  onMatchAllServicesChange: (checked: boolean) => void;
  onResidualChange: (value: string[]) => void;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onScrubToggleChange: (checked: boolean) => void;
  gridApi?: any;
}

const serviceOptions = [
  'Cable',
  'Data Centre',
  'Fibre Internet',
  'GPON',
  'Microsoft 365',
  'RES',
  'SIP',
  'Unison'
];

const residualOptions = [
  { value: '0', label: 'Not Eligible' },
  { value: '1', label: 'Active' },
  { value: '2', label: 'Pending Start' },
  { value: '3', label: 'Issue: Not Found' },
  { value: '4', label: 'Issue: Not Active' },
  { value: '5', label: 'Issue: Pending' }
];

export const BaseScrubFilters: React.FC<BaseScrubFiltersProps> = ({
  selectedServices,
  matchAllServices,
  selectedResiduals,
  searchTerm,
  showNeedsScrubbing,
  onServiceChange,
  onMatchAllServicesChange,
  onResidualChange,
  onSearchChange,
  onScrubToggleChange,
  gridApi,
}) => {
  const handleExportClick = () => {
    if (gridApi) {
      gridApi.exportDataAsCsv({
        fileName: `base-scrub-export-${new Date().toISOString().split('T')[0]}.csv`,
        processCellCallback: (params: any) => {
          // Format Services column - match the actual column field name
          if (params.column.getColId() === 'Services') {  // This matches the headerName in the grid
            const services = [];
            if (params.data.foxy_cable) services.push('Cable');
            if (params.data.foxy_datacentre) services.push('Data Centre');
            if (params.data.foxy_fibreinternet) services.push('Fibre Internet');
            if (params.data.foxy_gpon) services.push('GPON');
            if (params.data.foxy_microsoft365) services.push('Microsoft 365');
            if (params.data.foxy_res) services.push('RES');
            if (params.data.foxy_sip) services.push('SIP');
            if (params.data.foxy_unison) services.push('Unison');
            return services.join(', ');
          }
          
          // Format Residual Status column
          if (params.column.getColId() === 'foxyflow_wirelineresiduals') {
            return getWirelineResidualsLabel(params.value);
          }
          
          return params.value;
        }
      });
    }
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
      <Col xs={24} sm={12} md={6}>
        <Input
          placeholder="Search by company name..."
          prefix={<SearchOutlined style={{ color: '#8B949E' }} />}
          value={searchTerm}
          onChange={onSearchChange}
          style={{ 
            width: '100%',
            backgroundColor: '#1c2333',
            borderColor: '#30363d',
            color: '#D1D5DA',
          }}
        />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Filter by Services"
            onChange={onServiceChange}
            allowClear
            value={selectedServices}
            dropdownStyle={{ backgroundColor: '#1c2333' }}
            className="dark-select"
          >
            {serviceOptions.map(service => (
              <Option key={service} value={service}>{service}</Option>
            ))}
          </Select>
          <Switch
            checked={matchAllServices}
            onChange={onMatchAllServicesChange}
            checkedChildren="All"
            unCheckedChildren="Any"
            style={{ 
              minWidth: '55px',
              backgroundColor: matchAllServices ? '#1890ff' : '#1c2333',
              borderColor: '#30363d'
            }}
          />
        </div>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Filter by Residual Status"
          onChange={onResidualChange}
          allowClear
          value={selectedResiduals}
          dropdownStyle={{ backgroundColor: '#1c2333' }}
          className="dark-select"
        >
          {residualOptions.map(option => (
            <Option key={option.value} value={option.value}>{option.label}</Option>
          ))}
        </Select>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Switch
            checked={showNeedsScrubbing}
            onChange={onScrubToggleChange}
            style={{ 
              backgroundColor: showNeedsScrubbing ? '#1890ff' : '#1c2333',
              borderColor: '#30363d'
            }}
          />
          <span style={{ color: '#D1D5DA' }}>Show Needs Scrubbing (120+ days)</span>
          <Button
            type="default"
            icon={<FileExcelOutlined />}
            onClick={handleExportClick}
            style={{
              marginLeft: 'auto',
              borderColor: '#434343',
              color: '#D1D5DA',
              backgroundColor: 'transparent',
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
            title="Export current view to Excel"
          >
            Export
          </Button>
        </div>
      </Col>
      <style>
        {`
          .dark-select .ant-select-selector {
            background-color: #1c2333 !important;
            border-color: #30363d !important;
            color: #D1D5DA !important;
          }
          .dark-select .ant-select-selection-placeholder {
            color: #8B949E !important;
          }
          .dark-select .ant-select-selection-item {
            background-color: #2d3343 !important;
            border-color: #30363d !important;
            color: #D1D5DA !important;
          }
          .ant-select-dropdown {
            background-color: #1c2333 !important;
          }
          .ant-select-item {
            color: #D1D5DA !important;
          }
          .ant-select-item-option-selected {
            background-color: #2d3343 !important;
          }
          .ant-select-item-option-active {
            background-color: #2d3343 !important;
          }
          .ant-input::placeholder {
            color: #8B949E !important;
            opacity: 1 !important;
          }
          .ant-switch {
            background-color: #1c2333 !important;
          }
          .ant-switch-checked {
            background-color: #1890ff !important;
          }
        `}
      </style>
    </Row>
  );
};
