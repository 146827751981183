import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { GroupedData, WonService, isGroupData } from '../../../types/wonServices';
import { getWonServicesColumns } from './tableColumns';
import type { Key } from 'antd/es/table/interface';
import { checkUserAccess, UserAccessLevel } from '../../../auth/authService';

interface WonServicesTableProps {
    data: GroupedData[];
    loading: boolean;
    expandedKeys: string[];
    selectedRowKeys: React.Key[];
    onExpandedRowsChange: (keys: string[]) => void;
    onSelectionChange: (keys: React.Key[]) => void;
    onViewDispute?: (record: WonService) => void;
}

const WonServicesTable: React.FC<WonServicesTableProps> = ({
    data,
    loading,
    expandedKeys,
    selectedRowKeys,
    onExpandedRowsChange,
    onSelectionChange,
    onViewDispute,
}) => {
    const [userAccess, setUserAccess] = useState<UserAccessLevel>('none');

    useEffect(() => {
        const fetchUserAccess = async () => {
            const access = await checkUserAccess();
            setUserAccess(access);
        };
        fetchUserAccess();
    }, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectionChange,
        getCheckboxProps: (record: GroupedData | WonService) => ({
            disabled: isGroupData(record), // Disable checkbox for parent rows
        }),
    };

    const expandableConfig = {
        expandedRowKeys: expandedKeys,
        onExpandedRowsChange: (keys: readonly Key[]) => onExpandedRowsChange(keys as string[]),
    };

    return (
        <div>
            <Table
                rowSelection={rowSelection}
                columns={getWonServicesColumns(userAccess, onViewDispute)}
                dataSource={data}
                loading={loading}
                rowKey={(record: GroupedData | WonService) => 
                    isGroupData(record) ? record.key : record.foxy_wonserviceid
                }
                scroll={{ x: 'max-content', y: 'calc(100vh - 280px)' }}
                className="custom-table"
                size="middle"
                pagination={false}
                expandable={expandableConfig}
            />
        </div>
    );
};

export default WonServicesTable;
