import React from 'react';
import { Select, Row, Col, Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { renewalDispositionMapper } from '../../../utils/constants/renewalDispositionMapper';

const { Option } = Select;

export interface RenewalCheckFiltersProps {
  _selectedServices: string[];
  _matchAllServices: boolean;
  selectedResiduals: string[];
  _searchTerm: string;
  _showNeedsScrubbing: boolean;
  _onServiceChange: (value: string[]) => void;
  _onMatchAllServicesChange: (checked: boolean) => void;
  onResidualChange: (value: string[]) => void;
  _onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  _onScrubToggleChange: (checked: boolean) => void;
  onApplyFilters: () => void;
  isFiltered?: boolean;
  onClearFilter?: () => void;
  hasSelectedRows?: boolean;
}

// Define the order and included values to match the context menu
const orderedDispositionValues = [
  // Eligibility
  612100004, // Not In Renewal Period
  612100001, // In Renewal Period
  // In Progress
  947760001, // In Review
  612100005, // Opportunity Created
  // Renewed
  612100009, // Renewed by Infusion
  // No Renewal
  612100000, // Can't Renew due to ROE
  612100003, // Lost to Competitor
  612100006, // Remaining Month to Month
  612100008, // Renewal Not Eligible
  612100010, // Service Cancelled
  612100011, // Other
  612100012, // Renewed by Rogers
  612100013  // Renewed by VAR/Dealer
];

const residualOptions = orderedDispositionValues.map(value => ({
  value: value.toString(),
  label: renewalDispositionMapper[value]
}));

const items = [
  { key: '1', label: 'Export Selected' },
  { key: '2', label: 'Mark as Complete' },
];

export const RenewalCheckFilters: React.FC<RenewalCheckFiltersProps> = ({
  _selectedServices,
  _matchAllServices,
  selectedResiduals,
  _searchTerm,
  _showNeedsScrubbing,
  _onServiceChange,
  _onMatchAllServicesChange,
  onResidualChange,
  _onSearchChange,
  _onScrubToggleChange,
  onApplyFilters,
  isFiltered,
  onClearFilter,
  hasSelectedRows
}) => {
  const handleApplyClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onApplyFilters();
  };

  return (
    <div className="renewal-check-filters">
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Select
              mode="multiple"
              style={{ width: '25%' }}
              placeholder="Filter by Renewal Status"
              onChange={onResidualChange}
              allowClear
              value={selectedResiduals}
              dropdownStyle={{ backgroundColor: '#1c2333' }}
              className="dark-select"
            >
              {residualOptions.map(option => (
                <Option key={option.value} value={option.value}>{option.label}</Option>
              ))}
            </Select>
            <Button 
              type="primary"
              onClick={handleApplyClick}
              style={{
                backgroundColor: '#1c2333',
                borderColor: '#30363d',
                color: '#D1D5DA',
              }}
              className="dark-button"
            >
              Apply
            </Button>
            {isFiltered && (
              <Button
                onClick={onClearFilter}
                style={{
                  backgroundColor: '#2b2b2b',
                  border: '1px solid #d9d9d9',
                  color: '#d9d9d9',
                }}
                className="dark-button"
              >
                Clear Filter
              </Button>
            )}
            {hasSelectedRows && (
              <Dropdown
                menu={{ items }}
                trigger={['click']}
                overlayStyle={{ backgroundColor: '#1c2333' }}
              >
                <Button
                  style={{
                    backgroundColor: '#2b2b2b',
                    border: '1px solid #d9d9d9',
                    color: '#d9d9d9',
                  }}
                  className="dark-button"
                >
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
        </Col>
        <style>
          {`
            .ant-select-selector {
              background-color: #1c2333 !important;
              border-color: #30363d !important;
              color: #D1D5DA !important;
            }
            .ant-select-selection-placeholder {
              color: #8B949E !important;
            }
            .ant-select-selection-item {
              background-color: #2d3343 !important;
              border-color: #30363d !important;
              color: #D1D5DA !important;
            }
            .ant-select-dropdown {
              background-color: #1c2333 !important;
            }
            .ant-select-item {
              color: #D1D5DA !important;
            }
            .ant-select-item-option-selected {
              background-color: #2d3343 !important;
            }
            .ant-select-item-option-active {
              background-color: #2d3343 !important;
            }
            .ant-select-item:hover {
              background-color: #2d3343 !important;
            }
            .ant-select-item-option-content {
              color: #D1D5DA !important;
            }
            .dark-button:hover {
              background-color: #2d3343 !important;
              border-color: #444c56 !important;
              color: #D1D5DA !important;
            }
            .dark-button:focus {
              background-color: #2d3343 !important;
              border-color: #444c56 !important;
              color: #D1D5DA !important;
            }
            .ant-dropdown-menu {
              background-color: #1c2333 !important;
              border: 1px solid #30363d !important;
            }
            .ant-dropdown-menu-item {
              color: #D1D5DA !important;
            }
            .ant-dropdown-menu-item:hover {
              background-color: #2d3343 !important;
            }
          `}
        </style>
      </Row>
    </div>
  );
};
