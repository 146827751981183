import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { checkUserAccess, UserAccessLevel } from './authService';

interface RouteGuardProps {
  children: JSX.Element;
  requiredAccess: UserAccessLevel;
}

const AccessDeniedPage = () => (
  <div style={{ 
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f2f5'
  }}>
    <img 
      src="/foxylogo.png" 
      alt="Foxy Logo" 
      style={{ 
        height: '60px', 
        marginBottom: '24px' 
      }} 
    />
    <div style={{
      padding: '24px',
      background: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      textAlign: 'center',
      maxWidth: '400px'
    }}>
      <h2 style={{ 
        margin: '0 0 16px 0',
        color: '#434343'
      }}>Access Restricted</h2>
      <p style={{ 
        margin: '0 0 8px 0',
        color: '#595959'
      }}>This page is only accessible to administrators.</p>
      <p style={{ 
        margin: '0',
        color: '#8c8c8c',
        fontSize: '14px'
      }}>Please contact your administrator if you believe this is an error.</p>
    </div>
  </div>
);

const RouteGuard: React.FC<RouteGuardProps> = ({ 
  children, 
  requiredAccess 
}) => {
  const [userAccess, setUserAccess] = useState<UserAccessLevel | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserAccess = async () => {
      try {
        const access = await checkUserAccess();
        setUserAccess(access);
      } catch (error) {
        console.error('Error checking user access:', error);
        setUserAccess('none');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserAccess();
  }, []);

  if (isLoading) {
    return (
      <div style={{ 
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f2f5'
      }}>
        Checking access...
      </div>
    );
  }

  // Allow admin users to access everything
  if (userAccess === 'admin') {
    return children;
  }

  // For regular users, only allow if the required access is 'user'
  if (userAccess === 'user' && requiredAccess === 'user') {
    return children;
  }

  // Show access denied page for admin-only routes
  if (requiredAccess === 'admin') {
    return <AccessDeniedPage />;
  }

  // Redirect to home page for other access denied cases
  return <Navigate to="/" replace />;
};

export default RouteGuard;
