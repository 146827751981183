import { useState, useEffect, useMemo } from 'react';
import { listAccountsForResidualCheck } from '../../../utils/api';

export interface Account {
  accountid: string;
  name: string;
  foxy_cable: boolean;
  foxy_datacentre: boolean;
  foxy_duns: string;
  foxy_fibreinternet: boolean;
  foxy_gpon: boolean;
  foxy_microsoft365: boolean;
  foxy_res: boolean;
  foxyflow_residualstotal: string;
  foxyflow_residualsnotes: string;
  foxy_ritaresidualnotes: string;
  foxy_sip: boolean;
  foxy_unison: boolean;
  foxy_wirelinemrr: string;
  foxyflow_wirelineresiduals: string;
  crc9f_residuallastscrub: string;
  crc9f_totalwonoppstcv: number | null;
}

interface FilterOptions {
  searchTerm: string;
  selectedServices: string[];
  selectedResiduals: string[];
  showNeedsScrubbing: boolean;
  matchAllServices: boolean;
}

export const useResidualCheckData = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(() => ({
    searchTerm: localStorage.getItem('residual_searchTerm') || '',
    selectedServices: JSON.parse(localStorage.getItem('residual_selectedServices') || '[]'),
    selectedResiduals: JSON.parse(localStorage.getItem('residual_selectedResiduals') || '[]'),
    showNeedsScrubbing: JSON.parse(localStorage.getItem('residual_showNeedsScrubbing') || 'false'),
    matchAllServices: JSON.parse(localStorage.getItem('residual_matchAllServices') || 'false'),
  }));

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await listAccountsForResidualCheck();
        setAccounts(response.value);
      } catch (err) {
        setError('Error fetching accounts. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  const filteredAccounts = useMemo(() => {
    let filtered = [...accounts];

    if (filterOptions.searchTerm) {
      filtered = filtered.filter(account => 
        account.name.toLowerCase().includes(filterOptions.searchTerm.toLowerCase())
      );
    }

    if (filterOptions.selectedServices.length > 0) {
      filtered = filtered.filter(account => {
        const accountServices = [
          account.foxy_cable && 'Cable',
          account.foxy_datacentre && 'Data Centre',
          account.foxy_fibreinternet && 'Fibre Internet',
          account.foxy_gpon && 'GPON',
          account.foxy_microsoft365 && 'Microsoft 365',
          account.foxy_res && 'RES',
          account.foxy_sip && 'SIP',
          account.foxy_unison && 'Unison'
        ].filter(Boolean) as string[];

        if (filterOptions.matchAllServices) {
          return filterOptions.selectedServices.length === accountServices.length &&
                 filterOptions.selectedServices.every(service => accountServices.includes(service));
        } else {
          return filterOptions.selectedServices.some(service => accountServices.includes(service));
        }
      });
    }

    if (filterOptions.selectedResiduals.length > 0) {
      filtered = filtered.filter(account => {
        const accountResidualValue = account.foxyflow_wirelineresiduals?.toString();
        return filterOptions.selectedResiduals.includes(accountResidualValue);
      });
    }

    if (filterOptions.showNeedsScrubbing) {
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - 120);

      filtered = filtered.filter(account => {
        if (!account.crc9f_residuallastscrub) return true;
        const scrubDate = new Date(account.crc9f_residuallastscrub);
        return scrubDate < cutoffDate;
      });
    }

    return filtered;
  }, [accounts, filterOptions]);

  const updateFilterOptions = (newOptions: Partial<FilterOptions>) => {
    setFilterOptions(prev => {
      const updated = { ...prev, ...newOptions };
      // Update localStorage for each changed option
      Object.entries(newOptions).forEach(([key, value]) => {
        localStorage.setItem(`residual_${key}`, JSON.stringify(value));
      });
      return updated;
    });
  };

  return {
    accounts: filteredAccounts,
    isLoading,
    error,
    filterOptions,
    updateFilterOptions,
  };
};
