import React from 'react';

interface Position {
  x: number;
  y: number;
}

interface CustomContextMenuProps {
  position: Position;
  onClose: () => void;
  rowData: any;
  _columnField?: string;
}

export const CustomContextMenu: React.FC<CustomContextMenuProps> = ({ 
  position, 
  onClose, 
  rowData,
  _columnField
}) => {
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest('.custom-context-menu')) {
        onClose();
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [onClose]);

  const handleMenuAction = (action: string) => {
    switch (action) {
      case 'copy':
        // Only include fields that are shown in the table
        const visibleData = {
          'Name': rowData.name,
          'DUNS': rowData.foxy_duns,
          'SFDC Owner': rowData.foxy_sfdcapiaccountowner,
          'Residuals Total': rowData.foxyflow_residualstotal,
          'Wireline MRR': rowData.foxy_wirelinemrr,
          'Won TCV': rowData.crc9f_totalwonoppstcv,
          'Residual Status': rowData.foxyflow_wirelineresiduals,
          'Last Scrubbed': rowData.crc9f_residuallastscrub
        };
        navigator.clipboard.writeText(JSON.stringify(visibleData, null, 2));
        break;
      case 'details':
        const serviceUrl = `https://foxy.crm3.dynamics.com/main.aspx?appid=a5e9eec5-dda4-eb11-9441-000d3a848fc5&pagetype=entityrecord&etn=account&id=${rowData.accountid}`;
        window.open(serviceUrl, '_blank', 'noopener,noreferrer');
        break;
      case 'viewOppFoxy':
        if (rowData.foxy_Opportunity?.opportunityid) {
          const oppUrl = `https://foxy.crm3.dynamics.com/main.aspx?appid=a5e9eec5-dda4-eb11-9441-000d3a848fc5&pagetype=entityrecord&etn=opportunity&id=${rowData.foxy_Opportunity.opportunityid}`;
          window.open(oppUrl, '_blank', 'noopener,noreferrer');
        }
        break;
      case 'viewOppSFDC':
        if (rowData.foxy_Opportunity?.foxy_sfdcoppid) {
          const sfdcUrl = `https://rogersb2b.lightning.force.com/_ui/search/ui/UnifiedSearchResults?str=${rowData.foxy_Opportunity.foxy_sfdcoppid}`;
          window.open(sfdcUrl, '_blank', 'noopener,noreferrer');
        }
        break;
    }
    onClose();
  };

  return (
    <div 
      className="custom-context-menu"
      style={{
        position: 'fixed',
        top: position.y,
        left: position.x,
        backgroundColor: '#1c2333',
        border: '1px solid #30363d',
        borderRadius: '4px',
        padding: '4px 0',
        minWidth: '160px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        zIndex: 1000,
      }}
    >
      <div 
        className="menu-item"
        onClick={() => handleMenuAction('copy')}
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          color: '#D1D5DA',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span style={{ fontSize: '12px' }}>📋</span>
        Copy Row Data
      </div>
      <div 
        className="menu-divider"
        style={{
          height: '1px',
          backgroundColor: '#30363d',
          margin: '4px 0'
        }}
      />
      <div 
        className="menu-item"
        onClick={() => handleMenuAction('details')}
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          color: '#D1D5DA',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span style={{ fontSize: '12px' }}>🦊</span>
        View Service in Foxy
      </div>
      <div 
        className="menu-item"
        onClick={() => handleMenuAction('viewOppFoxy')}
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          color: '#D1D5DA',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span style={{ fontSize: '12px' }}>🦊</span>
        View Opp in Foxy
      </div>
      <div 
        className="menu-divider"
        style={{
          height: '1px',
          backgroundColor: '#30363d',
          margin: '4px 0'
        }}
      />
      <div 
        className="menu-item"
        onClick={() => handleMenuAction('viewOppSFDC')}
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          color: '#D1D5DA',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span style={{ fontSize: '12px' }}>☁️</span>
        View Opp in SFDC
      </div>
      <style>
        {`
          .menu-item:hover {
            background-color: #2d3343;
          }
        `}
      </style>
    </div>
  );
};
