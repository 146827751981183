import React from 'react';
import { renewalDispositionMapper } from '../../../utils/constants/renewalDispositionMapper';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';

interface Position {
  x: number;
  y: number;
}

interface CustomContextMenuProps {
  position: Position;
  onClose: () => void;
  rowData: any;
  columnField?: string;
  selectedCount?: number;
  onDispositionChange?: (value: number) => void;
}

type DispositionValue = number;

// Menu sections configuration
const menuSections: {
  [key: string]: {
    title: string;
    icon: JSX.Element;
    items: DispositionValue[];
  }
} = {
  eligibility: {
    title: 'Eligibility',
    icon: <ClockCircleOutlined style={{ color: '#52c41a' }} />,
    items: [
      612100004, // Not In Renewal Period
      612100001  // In Renewal Period
    ]
  },
  inProgress: {
    title: 'In Progress',
    icon: <SyncOutlined style={{ color: '#1890ff' }} />,
    items: [
      947760001, // In Review
      612100005  // Opportunity Created
    ]
  },
  renewed: {
    title: 'Renewed',
    icon: <CheckCircleOutlined style={{ color: '#faad14' }} />,
    items: [
      612100009  // Renewed by Infusion
    ]
  },
  noRenewal: {
    title: 'No Renewal',
    icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />,
    items: [
      612100000, // Can't Renew due to ROE
      612100003, // Lost to Competitor
      612100006, // Remaining Month to Month
      612100008, // Renewal Not Eligible
      612100010, // Service Cancelled
      612100011, // Other
      612100012, // Renewed by Rogers
      612100013  // Renewed by VAR/Dealer
    ]
  }
};

export const CustomContextMenu: React.FC<CustomContextMenuProps> = ({ 
  position, 
  onClose, 
  rowData, 
  columnField,
  selectedCount = 0,
  onDispositionChange
}) => {
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest('.custom-context-menu')) {
        onClose();
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [onClose]);

  const handleDispositionChange = (value: DispositionValue) => {
    if (onDispositionChange) {
      onDispositionChange(value);
    }
    onClose();
  };

  const handleMenuAction = (action: string) => {
    switch (action) {
      case 'copy':
        // Only include fields that are shown in the table
        const visibleData = {
          'SFDC Opp ID': rowData.foxy_Opportunity?.foxy_sfdcoppid,
          'Product': rowData.foxy_Product?.name,
          'Renewal Disposition': renewalDispositionMapper[rowData.foxy_renewaldisposition],
          'Account': rowData.foxy_Account?.name,
          'Renewal Eligible': rowData.foxy_renewaleligible ? new Date(rowData.foxy_renewaleligible).toLocaleDateString() : '',
          'Contract End': rowData.foxy_contractend ? new Date(rowData.foxy_contractend).toLocaleDateString() : '',
          'Address': rowData.foxy_AccountLocation?.foxy_Building?.foxy_fulladdress,
          'Term': rowData.foxy_term,
          'Quantity': rowData.foxy_quantity,
          'MRR': rowData.foxy_mrr,
          'Revenue Type': rowData.foxy_revenuetype,
          'Opportunity': rowData.foxy_Opportunity?.name,
          'Close Date': rowData.foxy_Opportunity?.actualclosedate ? new Date(rowData.foxy_Opportunity?.actualclosedate).toLocaleDateString() : '',
          'MRR Uptick': rowData.foxy_mrruptick,
          'TCV': rowData.foxy_tcv,
          'Line Margin': rowData.foxy_linemargin ? `${(rowData.foxy_linemargin * 100).toFixed(1)}%` : '',
          'Renewal Type': rowData.foxy_renewaltype
        };
        console.log('Copying visible data:', visibleData);
        navigator.clipboard.writeText(JSON.stringify(visibleData, null, 2));
        break;
      case 'export':
        console.log('Export:', rowData);
        break;
      case 'details':
        const serviceUrl = `https://foxy.crm3.dynamics.com/main.aspx?appid=a5e9eec5-dda4-eb11-9441-000d3a848fc5&pagetype=entityrecord&etn=foxy_wonservice&id=${rowData.foxy_wonserviceid}`;
        console.log('Attempting to open URL:', serviceUrl);
        console.log('Row Data:', rowData);
        window.open(serviceUrl, '_blank', 'noopener,noreferrer');
        break;
      case 'viewOppFoxy':
        const opportunityId = rowData.foxy_Opportunity?.opportunityid;
        if (opportunityId) {
          const oppUrl = `https://foxy.crm3.dynamics.com/main.aspx?appid=a5e9eec5-dda4-eb11-9441-000d3a848fc5&pagetype=entityrecord&etn=opportunity&id=${opportunityId}`;
          window.open(oppUrl, '_blank', 'noopener,noreferrer');
        }
        break;
      case 'viewOppSFDC':
        const sfdcOppId = rowData.foxy_Opportunity?.foxy_sfdcoppid;
        if (sfdcOppId) {
          const sfdcUrl = `https://rogersb2b.lightning.force.com/_ui/search/ui/UnifiedSearchResults?str=${sfdcOppId}`;
          window.open(sfdcUrl, '_blank', 'noopener,noreferrer');
        }
        break;
    }
    onClose();
  };

  // If we're in the Renewal Disposition column, show disposition options
  if (columnField === 'foxy_renewaldisposition') {
    return (
      <div 
        className="custom-context-menu"
        style={{
          position: 'fixed',
          top: position.y,
          left: position.x,
          backgroundColor: '#1c2333',
          border: '1px solid #30363d',
          borderRadius: '6px',
          padding: '4px 0',
          minWidth: '240px',
          maxHeight: '400px',
          overflowY: 'auto',
          boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
          zIndex: 1000,
        }}
      >
        {Object.entries(menuSections).map(([sectionKey, section]) => (
          <React.Fragment key={sectionKey}>
            <div style={{
              padding: '8px 12px',
              color: '#8B949E',
              fontSize: '12px',
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              borderBottom: '1px solid #30363d',
              backgroundColor: '#161b2e'
            }}>
              {section.icon}
              {section.title}
            </div>
            {section.items.map(value => (
              <div 
                key={value}
                onClick={() => handleDispositionChange(value)}
                style={{
                  padding: '8px 12px 8px 32px',
                  cursor: 'pointer',
                  color: '#D1D5DA',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  backgroundColor: rowData.foxy_renewaldisposition === value ? '#2d3343' : 'transparent',
                  justifyContent: 'space-between',
                }}
                className="menu-item"
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {rowData.foxy_renewaldisposition === value && (
                    <span style={{ fontSize: '12px', color: '#1890ff' }}>✓</span>
                  )}
                  {renewalDispositionMapper[value]}
                </div>
                {selectedCount > 0 && (
                  <span style={{ 
                    color: '#8B949E',
                    fontSize: '12px',
                  }}>
                    ({selectedCount})
                  </span>
                )}
              </div>
            ))}
          </React.Fragment>
        ))}
        <style>
          {`
            .menu-item:hover {
              background-color: #2d3343 !important;
            }
            .custom-context-menu::-webkit-scrollbar {
              width: 8px;
            }
            .custom-context-menu::-webkit-scrollbar-track {
              background: #1c2333;
            }
            .custom-context-menu::-webkit-scrollbar-thumb {
              background: #30363d;
              border-radius: 4px;
            }
            .custom-context-menu::-webkit-scrollbar-thumb:hover {
              background: #444c56;
            }
          `}
        </style>
      </div>
    );
  }

  // Default menu for other columns
  return (
    <div 
      className="custom-context-menu"
      style={{
        position: 'fixed',
        top: position.y,
        left: position.x,
        backgroundColor: '#1c2333',
        border: '1px solid #30363d',
        borderRadius: '4px',
        padding: '4px 0',
        minWidth: '160px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        zIndex: 1000,
      }}
    >
      <div 
        className="menu-item"
        onClick={() => handleMenuAction('copy')}
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          color: '#D1D5DA',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span style={{ fontSize: '12px' }}>📋</span>
        Copy Row Data
      </div>
      <div 
        className="menu-divider"
        style={{
          height: '1px',
          backgroundColor: '#30363d',
          margin: '4px 0'
        }}
      />
      <div 
        className="menu-item"
        onClick={() => handleMenuAction('details')}
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          color: '#D1D5DA',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span style={{ fontSize: '12px' }}>🦊</span>
        View Service in Foxy
      </div>
      <div 
        className="menu-item"
        onClick={() => handleMenuAction('viewOppFoxy')}
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          color: '#D1D5DA',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span style={{ fontSize: '12px' }}>🦊</span>
        View Opp in Foxy
      </div>
      <div 
        className="menu-divider"
        style={{
          height: '1px',
          backgroundColor: '#30363d',
          margin: '4px 0'
        }}
      />
      <div 
        className="menu-item"
        onClick={() => handleMenuAction('viewOppSFDC')}
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          color: '#D1D5DA',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span style={{ fontSize: '12px' }}>☁️</span>
        View Opp in SFDC
      </div>
      <style>
        {`
          .menu-item:hover {
            background-color: #2d3343;
          }
        `}
      </style>
    </div>
  );
};
