import React from 'react';
import { useBaseScrubData } from './hooks/useBaseScrubData';
import { BaseScrubFilters } from './components/BaseScrubFilters';
import { BaseScrubGrid } from './tables/BaseScrubGrid';

const BaseScrubAGGridPage: React.FC = () => {
  const {
    accounts,
    isLoading,
    error,
    filterOptions,
    updateFilterOptions,
  } = useBaseScrubData();

  const [gridApi, setGridApi] = React.useState<any>(null);

  if (isLoading) return <div style={{ color: '#D1D5DA', padding: '24px 24px 8px' }}>Loading...</div>;
  if (error) return <div style={{ color: '#D1D5DA', padding: '24px 24px 8px' }}>{error}</div>;

  return (
    <div style={{ padding: '8px 24px 16px' }}>
      <h2 style={{ 
        color: '#D1D5DA', 
        marginBottom: '8px',
        fontWeight: 500
      }}>
        Accounts for Base Scrub (AG Grid)
      </h2>
      <div style={{ 
        color: '#8B949E', 
        fontSize: '14px', 
        marginBottom: '8px' 
      }}>
        Displaying {accounts.length} {accounts.length === 1 ? 'account' : 'accounts'}
      </div>

      <BaseScrubFilters
        selectedServices={filterOptions.selectedServices}
        matchAllServices={filterOptions.matchAllServices}
        selectedResiduals={filterOptions.selectedResiduals}
        searchTerm={filterOptions.searchTerm}
        showNeedsScrubbing={filterOptions.showNeedsScrubbing}
        onServiceChange={(value) => updateFilterOptions({ selectedServices: value })}
        onMatchAllServicesChange={(checked) => updateFilterOptions({ matchAllServices: checked })}
        onResidualChange={(value) => updateFilterOptions({ selectedResiduals: value })}
        onSearchChange={(e) => updateFilterOptions({ searchTerm: e.target.value })}
        onScrubToggleChange={(checked) => updateFilterOptions({ showNeedsScrubbing: checked })}
        gridApi={gridApi}
      />

      <div style={{ height: 'calc(100vh - 200px)' }}>
        <BaseScrubGrid 
          accounts={accounts} 
          onGridReady={(params) => setGridApi(params.api)}
        />
      </div>
    </div>
  );
};

export default BaseScrubAGGridPage;
